import React, { useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BrandCTA from 'components/BrandCta';
import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import ColorBlock from 'components/Content/ColorBlock';
import AlertBox from 'components/Content/AlertBox';

import NDPatientNSSHeroMobile from '../../assets/images/ND-patient-NascobalNS-mobile.png';
import img75 from '../../assets/images/nutrition-pat-75-percent.png';
import imgSprayStep1 from '../../assets/images/nasal-spray-step1.png';
import imgSprayStep2 from '../../assets/images/nasal-spray-step2.png';
import imgSprayStep3 from '../../assets/images/nasal-spray-step3.png';
import './nasal-spray.scss';

let Subscription = null;

const nasalsprayPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;

  const brandNames = {
    nascobal: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | NASCOBAL® Nasal Spray';
  const pageDescription =
    'What is NASCOBAL® (Cyanocobalamin, USP) Nasal Spray?';

  const renderNasalSpray = () => {
    return (
      <div>
        <ContentBlock className="no-padding-top">
          <Grid
            fluid
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>
                    <span
                      className="brandname-nascobal txt-uppercase"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    <br className="hide-only-mobile" />
                    NASAL SPRAY
                  </h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDPatientNSSHeroMobile} />
                </div>
              </Col>
            </Row>
            <ColorBlock white>
              <Row>
                <Col xs={12} md={12}>
                  <h2 className="strong">
                    What is{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />
                    ?
                  </h2>
                  <p>
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    (Cyanocobalamin, USP) Nasal Spray is a prescription medicine
                    used as a supplement to treat vitamin B
                    <sub className="vitaminb12-sub">12</sub> deficiency. People
                    who have had weight loss (bariatric) surgery may not absorb
                    enough vitamin B<sub className="vitaminb12-sub">12</sub>{' '}
                    from food, and are at increased risk of developing vitamin B
                    <sub className="vitaminb12-sub">12</sub> deficiency.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <h2 className="strong top-spacer">
                    Why{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.nascobal,
                      }}
                    />{' '}
                    is a good option:
                  </h2>
                  <ul className="list-regular">
                    <li>
                      First FDA-approved vitamin B
                      <sub className="vitaminb12-sub">12</sub> nasal spray
                    </li>
                    <li>Simple, once-weekly dosing*</li>
                    <li>
                      Fewer visits to the doctor’s office for vitamin B
                      <sub className="vitaminb12-sub">12</sub> dose
                    </li>
                    <li>Not a painful injection</li>
                    <li>Tasteless and odorless fine mist</li>
                    <li>
                      Increased and maintained vitamin B
                      <sub className="vitaminb12-sub">12</sub> levels in
                      clinical trials
                    </li>
                    <li>
                      Absorbed directly into the bloodstream—designed to bypass
                      the stomach and digestive tract
                    </li>
                    <li>Self-administered</li>
                  </ul>
                </Col>
                <Col xs={12} md={6} className="center-xs">
                  <div>
                    <img
                      src={img75}
                      className="img75"
                      alt="nutrition-patient-46-percent"
                    />
                  </div>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock white>
              <Row>
                <Col xs={12}>
                  <ColorBlock white>
                    <p className="sub-title">
                      Follow the steps below to see how simple it is to use
                      NASCOBAL
                      <sup className="brand-reg-mark">&reg;</sup>
                    </p>
                  </ColorBlock>
                </Col>
              </Row>
            </ColorBlock>
            <ColorBlock blue id="colorblock-nasal-spray">
              <Row>
                <Col xs={12} md={4}>
                  <img
                    src={imgSprayStep1}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step1"
                  />
                  <ul>
                    <li>
                      The unit contains only 1 spray:
                      <br />
                      <strong>DO NOT prime before use</strong>
                    </li>
                    <li>Blow nose gently to clear both nostrils</li>
                    <li>
                      Hold the unit with your thumb, supporting it at the
                      bottom, and your index and middle fingers on either side
                      of the nozzle
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={imgSprayStep2}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step2"
                  />
                  <ul>
                    <li>
                      Gently close 1 nostril with your other index finger.
                      Insert the nozzle into open nostril approximately ½ inch,
                      or as far as feels comfortable, and tilt your head
                      slightly forward. Do not press the plunger yet{' '}
                    </li>
                    <li>
                      Breathe in gently through your nose, close your mouth, and
                      at the same time, press the plunger firmly with your thumb
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4}>
                  <img
                    src={imgSprayStep3}
                    style={{ marginBottom: 0 }}
                    alt="nasal-spray-step3"
                  />
                  <ul>
                    <li>
                      Remove the nozzle from your nostril. At the same time,
                      keep your head level for 10 to 20 seconds while gently
                      breathing in through your nose and breathing out through
                      your mouth
                    </li>
                  </ul>
                </Col>
              </Row>
              <AlertBox>
                Do not drink hot liquids or eat hot foods at least 1 hour before
                and 1 hour after taking{' '}
                <span
                  className="brandname-nascobal"
                  dangerouslySetInnerHTML={{
                    __html: brandNames.NASCOBAL,
                  }}
                />{' '}
                Nasal Spray.
                <p className="text-blue">
                  <strong>*Dose adjustments may be required.</strong>
                </p>
              </AlertBox>
            </ColorBlock>
          </Grid>
        </ContentBlock>
        <Row>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/why-nutrition-direct"
              LinkCaption="WHY NUTRITION DIRECT™"
            >
              WHY{' '}
              <span
                className="brandname-nutrition txt-uppercase"
                dangerouslySetInnerHTML={{
                  __html: brandNames.NUTRITION,
                }}
              />
            </BrandCTA>
          </Col>
          <Col xs={12} md={6}>
            <BrandCTA
              href="/nutritiondirect/recipes"
              LinkCaption="GET HEALTHY RECIPES"
            >
              GET HEALTHY RECIPES
            </BrandCTA>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-patient-nasal-spray">
        {renderNasalSpray()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout
      indication={indication}
      className="nutrition-patient-nasal-spray-page"
    >
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default nasalsprayPage;
